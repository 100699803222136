<template>
  <div class="inner">
    <carousel-3d
      :controls-visible="true"
      :border="0"
      :width="1000"
      :height="556"
      :startIndex="0"
      :controls-prev-html="' &#10094; '"
      :controls-next-html="' &#10095; '"
      :controls-width="50"
      :controls-height="50"
      :inverse-scaling="400"
      :perspective="50"
      :space="300"
      :animationSpeed="400"
      :loop="true"
      :autoplay="true"
      :autoplay-timeout="4000"
      :autoplay-hover-pause="true"
    >
      <slide :index="0">
        <a href="/fototorte-weihnachten">
          <div class="herotext">
            <div class="herotitle long-title">Verschenke Einzigartiges</div>
            <div class="htext">
              Mit persönlich gestalteten Weihnachtsleckereien
            </div>
            <div class="hbutton">
              <button class="btn is--primary">Jetzt entdecken</button>
            </div>
          </div>
          <div class="clear-fix"></div>
          <img
            src="https://www.fototorte24.de/media/image/59/6a/bc/banner-weihnachten-3.jpg"
          />
        </a>
      </slide>
      <slide :index="1">
        <a href="/tortenaufleger-kindergeburtstag">
          <div class="herotext">
            <div class="herotitle long-title">
              Der perfekte Kindergeburtstag
            </div>
            <div class="htext">
              Überrraschen Sie Ihren Liebling mit leckeren Zuckerbildern
            </div>
            <div class="hbutton">
              <button class="btn is--primary">Jetzt entdecken</button>
            </div>
          </div>
          <div class="clear-fix"></div>
          <img
            src="https://www.fototorte24.de/media/image/9a/f7/b8/banner-kindergeburtstag.jpg"
          />
        </a>
      </slide>
      <slide :index="2">
        <a href="/fototorten/">
          <div class="herotext">
            <div class="herotitle">Fototorten</div>
            <div class="htext">
              Individuell bedrucken – das perfekte Geschenk für jeden Anlass
            </div>
            <div class="hbutton">
              <button class="btn is--primary">Jetzt entdecken</button>
            </div>
          </div>
          <div class="clear-fix"></div>
          <img
            src="https://www.fototorte24.de/media/image/7b/40/ee/banner-fototorten.jpg"
          />
        </a>
      </slide>
      <slide :index="3">
        <a href="/zuckerbilder/">
          <div class="herotext">
            <div class="herotitle">Zuckerbilder</div>
            <div class="htext">
              Ihr Lieblingsbild als Foto auf einer Torte – zum Anbeißen!
            </div>
            <div class="hbutton">
              <button class="btn is--primary">Jetzt entdecken</button>
            </div>
          </div>
          <div class="clear-fix"></div>
          <img
            src="https://www.fototorte24.de/media/image/af/25/6f/banner-zuckerbilder.jpg"
          />
        </a>
      </slide>
      <slide :index="4">
        <a href="/bedruckte-suessigkeiten/">
          <div class="herotext">
            <div class="herotitle long-title">Personalisierte Süßigkeiten</div>
            <div class="htext">
              Bedruckte Schokoladen, Drops und Kekse für besondere Momente
            </div>
            <div class="hbutton">
              <button class="btn is--primary">Jetzt entdecken</button>
            </div>
          </div>
          <div class="clear-fix"></div>
          <img
            src="https://www.fototorte24.de/media/image/67/22/a1/banner-suessigkeiten.jpg"
          />
        </a>
      </slide>

      <slide :index="5">
        <a href="/tortenbilder-hochzeit">
          <div class="herotext">
            <div class="herotitle long-title">Tortenbilder zur Hochzeit</div>
            <div class="htext">
              Sagen Sie Ja – zu unseren individuell gestalteten Fototorten
            </div>
            <div class="hbutton">
              <button class="btn is--primary">Jetzt entdecken</button>
            </div>
          </div>
          <div class="clear-fix"></div>
          <img
            src="https://www.fototorte24.de/media/image/e2/ae/5d/banner-tortenbilder.png"
          />
        </a>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "App",
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style>
.content--home .inner {
  max-width: 1500px;
  margin: auto;
}

.clear-fix {
  clear: both;
  display: table;
}
.carousel-3d-container .carousel-3d-controls .next,
.carousel-3d-container .carousel-3d-controls .prev {
  color: #fff;
}

.right-1:after,
.left-1:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}
.right-2:after,
.left-2:after {
  content: " ";
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}
.herotext {
  position: absolute;
  bottom: 0;
  background: rgba(2255, 255, 255, 0.85);
  width: 100%;
  padding: 0 28px 19px 28px;
}
.herotext .herotitle {
  font-size: 31px;
  font-family: "Oswald", serif;
  font-weight: bold;
  text-align: center;
  height: 50px;
  line-height: 45px;
  top: 11px;
  position: relative;
}
.herotext .herotitle.long-title {
  font-size: 25px;
}
.herotext .htext {
  font-size: 15px;
  display: none;
  font-family: Open sans;
  color: black;
  text-align: center;
}
.herotext .hbutton {
  width: 84%;
  bottom: 16px;
  position: absolute;
  display: none;
}
.herotext .hbutton .btn.is--primary {
  margin: auto;
  display: block;
  width: 195px;
  height: 55px;
  text-align: center;
  font-size: 15px;
  box-shadow: 2px 3px #aaa;
}
@media screen and (min-width: 45em) {
  .herotext {
    height: auto;
  }
  .herotext .herotitle {
    font-size: 50px;
    text-align: left;
    width: 75%;
    float: left;
    height: unset;
    line-height: unset;
    top: unset;
  }
  .herotext .herotitle.long-title {
    font-size: 36px;
    line-height: 69px;
  }
  .herotext .htext {
    font-size: 20px;
    text-align: left;
    width: 70%;
    float: left;
  }
  .herotext .hbutton {
    width: 20%;
    float: right;
    position: relative;
    bottom: 28px;
    display: block;
    top: 16px;
  }
  .herotext .hbutton .btn.is--primary {
    font-size: 20px;
    margin: 0;
    float: right;
  }
}
@media screen and (min-width: 55em) {
  .herotext .herotitle.long-title {
    font-size: 48px;
  }
  .herotext .htext {
    width: 75%;
    display: inline-block;
  }
  .herotext .hbutton {
    top: unset;
  }
}
</style>
